<div class="typesWrap">
    <mat-grid-list cols="3" rowHeight="50vh" [gutterSize]="'0px'" (window:resize)="onResize($event)" [cols]="breakpoint">
      <mat-grid-tile>
          <div class="type type-1" [@image]>
            <h1>The 5 CISO types</h1>
          </div>
      </mat-grid-tile>
      <mat-grid-tile (mouseover)="addMatClass=true" (mouseout)="addMatClass=false" [ngClass]="{'flipMat_hover':addMatClass}" (mouseover)="addClass=true" (mouseout)="addClass=false"  [style.background-color]="'#F6871F'" class="mat-tile-type2">
          <div class="type type-2" [@image]>
              <div class="flip-container" [routerLink]="['/type-details', 'data-steward-ciso']">
                    <div class="flipper" [ngClass]="{'flip_hover':addClass}">

                      <div class="front">
                          <img src="./assets/img/Data-Steward-CISO.gif" alt="Photo of a Shiba Inu">
                      </div>
                      <div class="back">
                          <h2 *ngIf="!dataStewardImge">Data Steward CISO</h2>
                      </div>

                  </div>
              </div>
          </div>
      </mat-grid-tile>
      <!-- <mat-grid-tile (mouseover)="hoverType2()" (mouseout)="hoverOutType2()">
        <div class="type type-2" [@image]>
            <div class="flip-container" [routerLink]="['/type-details', 'data-steward-ciso']">
                  <div class="flipper" [ngClass]="{'flip_hover':addClassType2}">

                    <div class="front">
                        <img src="./assets/img/Data-Steward-CISO.gif" alt="Photo of a Shiba Inu">
                    </div>
                    <div class="back" #type2Content>

                    </div>

                </div>
            </div>
        </div>
    </mat-grid-tile> -->
      <mat-grid-tile (mouseover)="addMatClass=true" (mouseout)="addMatClass=false" [ngClass]="{'flipMat_hover':addMatClass}" (mouseover)="addClass=true" (mouseout)="addClass=false"  [style.background-color]="'#03a38d'" class="mat-tile-type3">
          <div class="type type-3" [@image]>
              <div class="flip-container" [routerLink]="['/type-details', 'digital-native-ciso']">
                  <div class="flipper" [@image] [ngClass]="{'flip_hover':addClass}">
                      <div class="front">
                          <img src="./assets/img/Digital-Native-CISO.gif" alt="Photo of a Shiba Inu">
                      </div>
                      <div class="back">
                          <h2 *ngIf="!digitalNativeImge">Digital Native CISO</h2>
                      </div>
                  </div>
              </div>
          </div>
        </mat-grid-tile>
      <mat-grid-tile (mouseover)="addMatClass=true" (mouseout)="addMatClass=false" [ngClass]="{'flipMat_hover':addMatClass}" (mouseover)="addClass=true" (mouseout)="addClass=false"  [style.background-color]="'#d54b2b'" class="mat-tile-type4">
          <div class="type type-4" [@image]>
              <div class="flip-container" [routerLink]="['/type-details', 'customer-advocate-ciso']">
                  <div class="flipper" [@image] [ngClass]="{'flip_hover':addClass}">
                      <div class="front">
                          <img src="./assets/img/Customer-Advocate-CISO.gif" alt="Photo of a Shiba Inu">
                      </div>
                      <div class="back">
                          <h2 *ngIf="!customerAdvocateImge">Customer Advocate CISO</h2>
                      </div>
                  </div>
              </div>
          </div>
        </mat-grid-tile>
      <mat-grid-tile (mouseover)="addMatClass=true" (mouseout)="addMatClass=false" [ngClass]="{'flipMat_hover':addMatClass}" (mouseover)="addClass=true" (mouseout)="addClass=false"  [style.background-color]="'#03436b'" class="mat-tile-type5">
          <div class="type type-5" [@image]>
              <div class="flip-container" [routerLink]="['/type-details', 'business-ciso']">
                  <div class="flipper" [@image] [ngClass]="{'flip_hover':addClass}">
                      <div class="front">
                          <img src="./assets/img/Business-CISO.gif" alt="Photo of a Shiba Inu">
                      </div>
                      <div class="back">
                          <h2 *ngIf="!businessImge">Business CISO</h2>
                      </div>
                  </div>
              </div>
          </div>
        </mat-grid-tile>
      <mat-grid-tile (mouseover)="addMatClass=true" (mouseout)="addMatClass=false" [ngClass]="{'flipMat_hover':addMatClass}" (mouseover)="addClass=true" (mouseout)="addClass=false"  [style.background-color]="'#04abc3'" class="mat-tile-type6">
          <div class="type type-6" [@image]>
              <div class="flip-container" [routerLink]="['/type-details', 'connector-ciso']">
                  <div class="flipper" [@image] [ngClass]="{'flip_hover':addClass}">
                      <div class="front">
                          <img src="./assets/img/Connector-CISO.gif" alt="Photo of a Shiba Inu">
                      </div>
                      <div class="back">
                          <h2 *ngIf="!connectorImge">Connector CISO</h2>
                      </div>
                  </div>
              </div>
          </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>





