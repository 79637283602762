import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { trigger,useAnimation, state, style, animate, transition } from '@angular/animations';
import { LocationStrategy } from "@angular/common";
import { bounce,
  flash,
  pulse,
  rubberBand,
  shake,
  swing,
  tada,
  wobble,
  jello,
  bounceIn,
  bounceInDown,
  bounceInLeft,
  bounceInRight,
  bounceInUp,
  bounceOut,
  bounceOutDown,
  bounceOutLeft,
  bounceOutRight,
  bounceOutUp,
  fadeIn,
  fadeInDown,
  fadeInUp,
  fadeInLeft,
  fadeInRight,
  fadeOut,
  fadeOutDown,
  fadeOutUp,
  fadeOutLeft,
  fadeOutRight,
  slideInDown,
  slideInUp,
  slideInLeft,
  slideInRight,
  slideOutDown,
  slideOutUp,
  slideOutLeft,
  slideOutRight,
  flip,
  flipInX,
  flipInY,
  flipOutX,
  flipOutY,
  lightSpeedIn,
  lightSpeedOut,
  rotateIn,
  rotateInDownLeft,
  rotateInDownRight,
  rotateInUpLeft,
  rotateInUpRight,
  rotateOut,
  rotateOutDownLeft,
  rotateOutDownRight,
  rotateOutUpLeft,
  rotateOutUpRight,
  hinge,
  jackInTheBox,
  rollIn,
  rollOut,
  zoomIn,
  zoomInDown,
  zoomInUp,
  zoomInLeft,
  zoomInRight,
  zoomOut,
  zoomOutDown,
  zoomOutUp,
  zoomOutLeft,
  zoomOutRight } from 'ng-animate';
@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
        width: '100px',
        height: '100px'
      })),
      state('final', style({
        width: '200px',
        height: '200px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('fadeInOutOne', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000))
    ]),
    trigger('fadeInOutTwo', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(2500)),
    ]),
    trigger('fadeInOutFast', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(100))
    ]),
    trigger('image', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2seconds

    }))]),
    trigger('textAnim', [transition('* => *', useAnimation(jello, {
      // Set the duration to 5seconds and delay to 2seconds

    }))])
  ]
})
export class TypesComponent implements OnInit,AfterViewInit {
  currentState = 'initial';
  dataStewardImge:boolean = false;
  digitalNativeImge:boolean = false;
  customerAdvocateImge:boolean = false;
  businessImge:boolean = false;
  connectorImge:boolean = false;
  breakpoint:number = 0;
  fullImagePath:any = '/assets/img/'
  changeText:boolean = false;
  addClass:boolean= false;
  addMatClass:boolean= false;
  addClassType2:boolean =false;
  addClassTextType2:boolean =false;
  @ViewChild('type2Content', { static: false })
  type2Content!: ElementRef;
  constructor(private locationStrategy: LocationStrategy) {

  }


  ngOnInit() {
    localStorage.setItem('finalSurveyResult', '')
    this.breakpoint = (window.innerWidth <= 767) ? 2 : 3;
    this.fullImagePath = '/assets/img/'
  }

  ngAfterViewInit(){

  }

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 767) ? 2 : 3;
  }

  hoverType2(){
    this.addClassType2 = true;
    setTimeout( () => {
      this.type2Content.nativeElement.innerHTML = `<h2 [@textAnim]>Data Steward CISO</h2>`
    },500)
  }

  hoverOutType2(){
    this.addClassType2 = false;
  }

  changeStateSteward(){
    this.dataStewardImge = true;
    this.digitalNativeImge = false
    this.customerAdvocateImge = false
    //this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  changeStateDigital(){
    this.digitalNativeImge = true
    this.customerAdvocateImge = false
    this.dataStewardImge = false
    this.connectorImge = false
    this.businessImge = false
  }

  changeStateCustomer(){
    this.customerAdvocateImge = true
    this.dataStewardImge = false
    this.digitalNativeImge = false
    this.connectorImge = false
    this.businessImge = false

  }

  changeStateBusiness(){
    this.businessImge = true
    this.customerAdvocateImge = false
    this.dataStewardImge = false
    this.digitalNativeImge = false
    this.connectorImge = false
  }

  changeStateConnector(){
    this.connectorImge = true
    this.businessImge = false
    this.customerAdvocateImge = false
    this.dataStewardImge = false
    this.digitalNativeImge = false
  }

}
