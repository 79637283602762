 <div  class="type-details">
    <div class="typecolumn typecolumn_content typecolumn_bg">
        <div class="typecolumn_inner_content" #content_fixed>
          <h1 class="subtitle" #subTitle_fixed>{{finalResult?.subTitle }}</h1>
          <div class="fixed" >

            <h1 #title_fixed>{{finalResult?.title}}</h1>
         </div>
            <div class="typeContent_scroll" [ngClass]="{'shareSticky':addStickyClass}">
                <div class="typeContent_scroll_inner" (scroll)="onWindowScroll($event)">
                    <div class="shadowContent_top"></div>
                    <h4 [ngStyle]="{'color': finalResult?.color}">{{finalResult?.subsmallTitle}}</h4>

                    <div class="typeContent" [innerHTML]="finalResult?.content"></div>
                    <div class="shadowContent"></div>

                </div>
                <div class="resultShare">

                        <div class="surveyBtn_wrap">
                            <div class="surveyBtn_inner">
                                <p>Share Your Results!</p>
                                <div ngClass="socialLinks" #showSocialShare>


                                </div>
                            </div>
                            <!-- <div class="surveyBtn_inner">
                              <button class="btnSurvey hyperLink"  mat-stroked-button color="primary" [@fadeInOut] (click)="startSurveyAgain()">Take another survey</button>
                            </div> -->
                        </div>

                    </div>
            </div>
        </div>
       </div>

        <div class="typecolumn typecolumn_img" [@image]>
            <div class="typecolumn_inner_img" [@image]>
                <img src={{finalResult?.img}}  alt={{finalResult?.title}}>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
