import { Component, OnInit, ViewChild, AfterViewInit, ElementRef,Inject } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cform',
  templateUrl: './cform.component.html',
  styleUrls: ['./cform.component.scss']
})
export class CformComponent implements OnInit, AfterViewInit {

  @ViewChild('cforms', { static: false })
  cforms!: ElementRef;
  public customerForm!: FormGroup;
  submitted = false;
  getfirstName:any = '';
  getLastName:any = '';
  getEmail:any = '';
  getCompany:any = '';
  getTitle:any = '';
  getCountry:any = '';
  getEMarkting:any = '';
  countries:Array<any> = [
    {value :'Australia'},
    {value:'Canada'},
    {value:'France'},
    {value:'Germany'},
    {value:'Hong Kong'},
    {value:'India'},
    {value:'Italy'},
    {value:'Japan'},
    {value:'Netherlands'},
    {value:'People\'s Republic of China'},
    {value:'Singapore'},
    {value:'United Kingdom'},
    {value:'United States'},
    {value:'Argentina'},
    {value:'Austria'},
    {value:'Bahrain'},
    {value:'Belgium'},
    {value:'Brazil'},
    {value:'Chile'},
    {value:'Colombia'},
    {value:'Czech Republic'},
    {value:'Denmark'},
    {value:'Ecuador'},
    {value:'Egypt'},
    {value:'Estonia'},
    {value:'Finland'},
    {value:'Greece'},
    {value:'Hungary'},
    {value:'Iceland'},
    {value:'Indonesia'},
    {value:'Kuwait'},
    {value:'Latvia'},
    {value:'Liechtenstein'},
    {value:'Lithuania'},
    {value:'Luxembourg'},
    {value:'Malaysia'},
    {value:'Malta'},
    {value:'Nepal'},
    {value:'New Zealand'},
    {value:'Norway'},
    {value:'Oman'},
    {value:'Peru'},
    {value:'Philippines'},
    {value:'Poland'},
    {value:'Portugal'},
    {value:'Qatar'},
    {value:'Saudi Arabia'},
    {value:'Slovakia'},
    {value:'Slovenia'},
    {value:'South Africa'},
    {value:'South Korea'},
    {value:'Spain'},
    {value:'Sweden'},
    {value:'Switzerland'},
    {value:'Taiwan (Republic of China)'},
    {value:'Thailand'},
    {value:'Turkey'},
    {value:'United Arab Emirates'},
    {value:'Venezuela'},
  ]
  selectedCountry:any = '';
  parseJsonResult:any;
  showFinalResult:any;
  cisoResult:any;
  constructor(private router: Router,private route : ActivatedRoute) { }

  ngOnInit(): void {

    if(localStorage.getItem("finalSurveyResult") == '' || localStorage.getItem("finalSurveyResult") == null){
       this.router.navigate(['/survey']);
     } else {
      this.parseJsonResult = localStorage.getItem("finalSurveyResult")
      this.showFinalResult = JSON.parse(this.parseJsonResult)
      if(this.showFinalResult.id == 'data-steward-ciso'){
        this.cisoResult = 'Data Steward CISO (Honey Bee)';
      } else if(this.showFinalResult.id == 'business-ciso'){
        this.cisoResult = 'Business CISO (Bear)';
      } else if(this.showFinalResult.id == 'customer-advocate-ciso'){
        this.cisoResult = 'Customer Advocate CISO (Dolphin)';
      } else if(this.showFinalResult.id == 'connector-ciso'){
        this.cisoResult = 'Connector CISO (Octopus)';
      } else if(this.showFinalResult.id == 'digital-native-ciso'){
        this.cisoResult = 'Digital Native CISO (Beaver)';
      }
     }
    localStorage.setItem('formSubmit', 'false')
    //this.loadScript('https://img03.en25.com/i/livevalidation_standalone.compressed.js');
    this.customerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      company: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      title: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      selectedCountry: new FormControl('', [Validators.required]),
      uAagree: new FormControl(false, [Validators.requiredTrue]),
      rAagree: new FormControl(false, [Validators.required]),
    });
  }

  ngAfterViewInit(){
    //this.handleFormSubmit()
    //this.cforms.nativeElement.innerHTML = ``;
    // history.pushState(null, 'document.title', window.location.href);
    // window.addEventListener('popstate', function (event)
    // {
    //   history.pushState(null, 'document.title', window.location.href);
    // });
  }

  handleFormSubmit(){
    localStorage.setItem('formSubmit', 'true')
    let form = document.createElement('form');
    form.action = 'https://s1967927849.t.eloqua.com/e/f2';
    form.method = 'POST';
    form.name = 'Participants-637472659470741005-637473686608474741'
    //<select name="country" id="fe7948" data-value="~~eloqua..type--emailfield..syntax--Country~~">
		//<option value="">Please select</option>
		//<option value="${this.getCountry}" selected>${this.getCountry}</option>
		//</select>

    form.innerHTML = `
    <input value="Participants-637472659470741005-637473686608474741" type="hidden" name="elqFormName">
    <input value="1967927849" type="hidden" name="elqSiteId">
    <input name="elqCampaignId" type="hidden">
    <input type="text" name="firstName" id="fe7817" value="${this.getfirstName}">
    <input type="text" name="lastName" id="fe7818" value="${this.getLastName}">
    <input type="text" name="emailAddress" id="fe7819" value="${this.getEmail}">
    <input type="text" name="company" id="fe7820" value="${this.getCompany}">
    <input type="text" name="title" id="fe7821" value="${this.getTitle}">
    <input type="text" name="country" id="fe7948" value="${this.getCountry}" data-value="~~eloqua..type--emailfield..syntax--Country~~">
    <input type="checkbox" name="singleCheckbox" id="fe7823" checked value="1">
    <input type="checkbox" name="Opt-In" id="fe7824" value="${this.getEMarkting}">
    <input type="hidden" name="hiddenField" id="fe7822" value="${this.cisoResult}">

    // form.innerHTML = `
    // <input value="CISONextSurveyParticipants" type="hidden" name="elqFormName">
    // <input value="1967927849" type="hidden" name="elqSiteId">
    // <input name="elqCampaignId" type="hidden">
    // <input type="text" name="firstName" id="fe7672" value="test">
    // <input type="text" name="lastName" id="fe7673" value="testlast">
    // <input type="text" name="emailAddress" id="fe7674" value="testlastx@gmail.com">
    // <input type="text" name="company" id="fe7675" value="test company">
    // <input type="text" name="title" id="fe7677" value="test title">
    // <input type="checkbox" name="singleCheckbox" id="fe7679" checked>
    // `;

    // the form must be in the document to submit it
    document.body.append(form);
    //return false;

    form.submit();
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.customerForm.controls[controlName].hasError(errorName);
  }

  getCustomer(data:any){ console.log(data.selectedCountry.value)
    this.getfirstName = data.firstName;
    this.getLastName = data.lastName;
    this.getEmail = data.email;
    this.getCompany = data.company;
    this.getTitle = data.title;
    this.getEMarkting = data.rAagree;
    this.getCountry = data.selectedCountry.value

    this.handleFormSubmit();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
