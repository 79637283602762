import { Component, OnInit, ViewChild, AfterViewInit, ElementRef,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router,NavigationStart,ActivatedRoute  } from '@angular/router';
import { trigger, state, style, animate, transition,useAnimation, stagger, query } from '@angular/animations';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { CommonserviceService } from './../service/commonservice.service'
import { TextAnimation } from 'ngx-teximate';
import { bounce,
  flash,
  pulse,
  rubberBand,
  shake,
  swing,
  tada,
  wobble,
  jello,
  bounceIn,
  bounceInDown,
  bounceInLeft,
  bounceInRight,
  bounceInUp,
  bounceOut,
  bounceOutDown,
  bounceOutLeft,
  bounceOutRight,
  bounceOutUp,
  fadeIn,
  fadeInDown,
  fadeInUp,
  fadeInLeft,
  fadeInRight,
  fadeOut,
  fadeOutDown,
  fadeOutUp,
  fadeOutLeft,
  fadeOutRight,
  slideInDown,
  slideInUp,
  slideInLeft,
  slideInRight,
  slideOutDown,
  slideOutUp,
  slideOutLeft,
  slideOutRight,
  flip,
  flipInX,
  flipInY,
  flipOutX,
  flipOutY,
  lightSpeedIn,
  lightSpeedOut,
  rotateIn,
  rotateInDownLeft,
  rotateInDownRight,
  rotateInUpLeft,
  rotateInUpRight,
  rotateOut,
  rotateOutDownLeft,
  rotateOutDownRight,
  rotateOutUpLeft,
  rotateOutUpRight,
  hinge,
  jackInTheBox,
  rollIn,
  rollOut,
  zoomIn,
  zoomInDown,
  zoomInUp,
  zoomInLeft,
  zoomInRight,
  zoomOut,
  zoomOutDown,
  zoomOutUp,
  zoomOutLeft,
  zoomOutRight } from 'ng-animate';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('final', style({
        opacity: 0,
        transform: 'scale(0)'
      })),
      transition('initial=>final', animate('0.1ms')),
      transition('final=>initial', animate('0.1ms'))
    ]),
    trigger('fadeInOutOne', [
      state('void', style({
        opacity: 0,
      })),
      transition('void <=> *', animate('0.1ms'))
    ]),
    trigger('balloonEffect', [
      state('initial', style({
        backgroundColor: 'green',
        transform: 'scale(1)'
      })),
      state('final', style({
        backgroundColor: 'red',
        transform: 'scale(1.5)'
      })),
      transition('final=>initial', animate('0.2ms')),
      transition('initial=>final', animate('0.2ms'))
    ]),

    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        transform: 'scale(1)',
      })),
      transition('void <=> *', animate('0.2ms')),
    ]),
    trigger('fadeInOut2', [
      state('void', style({
        opacity: 0,
        transform: 'scale(2)',
      })),
      transition('void <=> *', animate('0.1ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('fadeInOut3', [
      state('void', style({
        opacity: 0,
        transform: 'scale(2)'
      })),
      transition('void <=> *', animate('0.1ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('fadeInOut4', [
      state('void', style({
        opacity: 0,
        transform: 'scale(2)',
      })),
      transition('void <=> *', animate('0.1ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),

    trigger('slideOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.2ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
        style({ opacity: '1' })
      ])
    ]),

    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        //style({ transform: 'translateX(-100%)' }),
        //animate('0.5s 300ms ease-in'),
        animate('0.1ms', style({ opacity: 0 }))
      ]),
      transition(':leave', [
        animate('0.1ms ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('qStart',[transition('* => *', useAnimation(flipInX))]),
    trigger('qFadein',[transition('* => *', useAnimation(flipInX))]),
    trigger('qAOne',[transition('* => *', useAnimation(flipInX))]),
    trigger('qATwo',[transition('* => *', useAnimation(flipInX))]),
    trigger('image',[transition('* => *', useAnimation(zoomInLeft))]),
  ]
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('getQuestion', { static: false })
  getQuestion!: ElementRef;
  @ViewChild('showButtonText', { static: false })
  showButtonText!: ElementRef;
  @ViewChild('setSocialIcon', {static: false})
  setSocialIcon!:ElementRef
  @ViewChild('subTitle_fixed', { static: false })
  subTitle_fixed!: ElementRef ;
  @ViewChild('title_fixed', { static: false })
  title_fixed!: ElementRef ;
  currentStateQ = 'initial';
  currentStateQZero = 'initial';
  currentStateQOne = 'initial';
  currentStateQTwo = 'initial';
  currentStateQThree = 'initial';
  currentStateQFour = 'initial';
  currentStateQFive = 'initial';
  currentStateQSix = 'initial';
  currentStateQSeven = 'initial';
  currentStateQEight = 'initial';
  currentStateQNine = 'initial';
  currentStateQTen = 'initial';

  currentBtnQ = 'final';
  currentBtnQZero = 'final';
  currentBtnQOne = 'final';
  currentBtnQTwo = 'final';
  currentBtnQThree = 'final';
  currentBtnQFour = 'final';
  currentBtnQFive = 'final';
  currentBtnQSix = 'final';
  currentBtnQSeven = 'final';
  currentBtnQEight = 'final';
  currentBtnQNine = 'final';
  currentBtnQTen = 'final';

  currentBtnAZero = 'final';
  currentBtnAOne = 'final';
  currentBtnATwo = 'final';
  currentBtnAThree = 'final';
  currentBtnAFour = 'final';
  currentBtnAFive = 'final';
  currentBtnASix = 'final';
  currentBtnASeven = 'final';
  currentBtnAEight = 'final';
  currentBtnANine = 'final';
  currentBtnATen = 'final';
  currentBtnATZero = 'final';

  surveyStarted: boolean = false;
  question: any = '';
  questionAnswerOne: string = ''
  testInitialize: boolean = true;
  surveyModule: boolean = false;
  questionValue: number = -1;
  getBusinessCiso: number = 0;
  getDataStewardCiso: number = 0;
  getCustomerAdvocateCiso: number = 0;
  getConnectorCiso: number = 0;
  getDigitalNativeCiso: number = 0;
  getFinalResultArry: Array<{ id: string, name: string, points: number,title:string,subTitle:string,subsmallTitle:any,color: string,img:any,content:any }> = [];

  finalResult: any;
  getpoints: any = [];
  anim: string = '';
  sendTextAnimStatus: boolean = false;
  hideTextAnimStatus: boolean = false;
  btnClicked: boolean = false;
  btnTitleZrIex: string = '';
  getCurrentId:number = 0;
  delayQZero:number=2;
  timingQZero:number=5;
  delayQOne:number=2;
  timingQOne:number=1;
  delayQTwo:number=2;
  timingQTwo:number=1;
  delayQThree:number=2;
  timingQThree:number=1;
  delayQFour:number=2;
  timingQFour:number=1;
  delayQFive:number=2;
  timingQFive:number=1;
  delayQSix:number=2;
  timingQSix:number=1;
  delayQSeven:number=2;
  timingQSeven:number=1;
  delayQEight:number=2;
  timingQEight:number=1;
  delayQNine:number=2;
  timingQNine:number=1;
  delayQTen:number=2;
  timingQTen:number=1;
  getDuplicateResults:any;
  formResultArray:any=[];
  surveyDetails: any = [
    {
      id: 0,
      question: 'I  tend   to   ask  for',
      answer: [{ 'qOne': 'The details' }, { 'qTwo': 'The big picture' }],
      type: ['CISO Type to whom it applies', 'Data Steward, Digital Native'],
      points: [
        {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 1,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0
        }
      ]
    },
    {
      id: 1,
      question: 'If given more budget, I\'d spend it on',
      answer: [{ 'qOne': 'More resources' }, { 'qTwo': 'More technology/partners' }],
      type: ['Digital Native, Customer Advocate', 'Data Steward, Connector, Business'],
      points: [
        {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1

        }, {
          Business_CISO: 1,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 0,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0
        }
      ]
    },
    {
      id: 2,
      question: 'When planning our go-forward strategy, I\'m',
      answer: [{ 'qOne': 'Precise and methodical' }, { 'qTwo': 'Dynamic and innovative' }],
      type: ['Data Steward', 'Digital Native'],
      points: [
        {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0
        }
      ]
    },
    {
      id: 3,
      question: 'I tend to think',
      answer: [{ 'qOne': 'A mile wide and an inch deep' }, { 'qTwo': 'An inch wide and a mile deep' }],
      type: ['Business, Connector', 'Data Steward, Digital Native, Customer Advocate'],
      points: [
        {
          Business_CISO: 1,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1
        }
      ]
    },
    {
      id: 4,
      question: 'I more often',
      answer: [{ 'qOne': 'Talk to think' }, { 'qTwo': 'Think to talk' }],
      type: ['Business, Connector', 'Data Steward,  Customer Advocate'],
      points: [
        {
          Business_CISO: 1,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1
        }
      ]
    },
    {
      id: 5,
      question: 'My first question after an event is about',
      answer: [{ 'qOne': 'Assets impacted' }, { 'qTwo': 'Attack vectors' }],
      type: ['Data Steward', 'Connector, Digital Native'],
      points: [
        {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 1,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0
        }
      ]
    },
    {
      id: 6,
      question: 'I tend to think in the context of',
      answer: [{ 'qOne': 'Numbers' }, { 'qTwo': 'People' }],
      type: ['Business, Digital Native, Data Steward', 'Customer Advocate, Connector'],
      points: [
        {
          Business_CISO: 1,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 1
        }
      ]
    },
    {
      id: 7,
      question: 'I\'m more often in a room with',
      answer: [{ 'qOne': 'The Board' }, { 'qTwo': 'The Engineers' }],
      type: ['Business, Connector', 'Digital Native, Data Steward, Customer Advocate'],
      points: [
        {
          Business_CISO: 1,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1
        }
      ]
    },
    {
      id: 8,
      question: 'My greatest asset',
      answer: [{ 'qOne': 'Curiosity' }, { 'qTwo': 'Resilience' }],
      type: ['Data Steward, Digital Native', 'Business, Customer Advocate'],
      points: [
        {
          Business_CISO: 0,
          Data_Steward_CISO: 1,
          Digital_Native_CISO: 1,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 1,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1
        }
      ]
    },
    {
      id: 9,
      question: 'There\'s one dessert left',
      answer: [{ 'qOne': 'First come, first served' }, { 'qTwo': 'Sharing is caring' }],
      type: ['Business', 'Customer Advocate'],
      points: [
        {
          Business_CISO: 1,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1
        }
      ]
    },
    {
      id: 10,
      question: 'When a friend brings a problem, I tend to',
      answer: [{ 'qOne': 'Propose a solution' }, { 'qTwo': 'Listen intently' }],
      type: ['Digital Native, Connector', 'Customer Advocate'],
      points: [
        {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 1,
          Connector_CISO: 1,
          Customer_Advocate_CISO: 0

        }, {
          Business_CISO: 0,
          Data_Steward_CISO: 0,
          Digital_Native_CISO: 0,
          Connector_CISO: 0,
          Customer_Advocate_CISO: 1
        }
      ]
    },
  ]

  allSpecies:any;
  specieSub:any;
  subscription!: Subscription;
  shareBtnCount:number = 11;
  enterAnimation: TextAnimation = {
    animation: fadeIn,
    delay: 20,
    type: 'letter'
  };

  btnTxtAnimation: TextAnimation = {
    animation: fadeIn,
    delay: 20,
    type: 'letter'
  };
  bounce: any;
  zoomInLeft:any;
  showSocialShare:boolean = false;
  emailstring= "mailto:xyz@example.com?Subject=Contact us&body=Hi,  %0DWrite your queries here  %0D Thanks";
  animationHomeOne:boolean = true;
  animationOneTime:number = 5000;
  animationHomeTwo:boolean = false;
  animationTwoTime:number = 10000;
  animationHomeThree:boolean = false;
  animationThreeTime:number = 15000;
  animationHomeFour:boolean = false;
  animationFourTime = 20000;
  animationHomeFive:boolean = false;
  animationFiveTime = 25000;
  chkLoopCnt:number = 0;
  strtAnmimation:number = 25000;
  lpAnim:number = 0;
  insideArryaCount:number = 5000;
  getHomeAnimationValue:string='animationHomeOne';
  setGifAnimationArray:Array<any>= ['animationHomeOne','animationHomeTwo','animationHomeThree','animationHomeFour','animationHomeFive'];
  constructor(private cmnSerive: CommonserviceService,private router : Router, @Inject(DOCUMENT) private document: Document) {
    if(localStorage.hasOwnProperty('finalSurveyResult')){
    if(localStorage.getItem("finalSurveyResult") != ''){
      this.router.navigate(['/result']);
    }
    }
  }

  async ngOnInit() {
    this.shareBtnCount = 11;
    //if(this.chkLoopCnt == 0) { this.strtAnmimation = 5000; this.insideArryaCount = 1000;}

    if(this.chkLoopCnt == 0 ) {

      setTimeout(() =>{
        this.ArrayPlusDelay(['animationHomeTwo','stop'], (obj) => {
        this.getHomeAnimationValue =  obj;
         },4000)
         this.chkLoopCnt = 1;
        },3500)

        setTimeout(() =>{
          this.ArrayPlusDelay(['animationHomeThree','stop'], (obj) => {
          this.getHomeAnimationValue =  obj;
           },4500)
          },7600)

          setTimeout(() =>{
            this.ArrayPlusDelay(['animationHomeFour','stop'], (obj) => {
            this.getHomeAnimationValue =  obj;
             },3500)
            },12200)

            setTimeout(() =>{
              this.ArrayPlusDelay(['animationHomeFive','stop'], (obj) => {
              this.getHomeAnimationValue =  obj;
               },4000)

              },15800)
      }


  }

  ngAfterViewInit() {
    this.btnTitleZrIex = 'Take the survey'
  }

  ArrayPlusDelay(array, delegate, delay) {

    // initialize all calls right away
    array.forEach(function (el, i) {
      setTimeout(function() {
          // each loop, call passed in function
          delegate( array[i]);

        // stagger the timeout for each loop by the index
        }, i * delay);
    })

  }

  async homeAnimationOne(){
      this.animationHomeOne = true
      this.animationHomeTwo = false
      this.animationHomeThree = false
      this.animationHomeFour = false
      this.animationHomeFive = false
  }

  async homeAnimationTwo(){
    this.chkLoopCnt = 1;
      this.animationHomeOne = false
      this.animationHomeTwo = true
      this.animationHomeThree = false
      this.animationHomeFour = false
      this.animationHomeFive = false
  }

  async homeAnimationThree(){
      this.animationHomeOne = false
      this.animationHomeTwo = false
      this.animationHomeThree = true
      this.animationHomeFour = false
      this.animationHomeFive = false
  }

  async homeAnimationFour(){
      this.animationHomeOne = false
      this.animationHomeTwo = false
      this.animationHomeThree = false
      this.animationHomeFour = true
      this.animationHomeFive = false
  }

  async homeAnimationFive(){
      this.animationHomeOne = false
      this.animationHomeTwo = false
      this.animationHomeThree = false
      this.animationHomeFour = false
      this.animationHomeFive = true
  }

  startSurvey(survey: boolean, qL: number) {
    //this.currentBtnQZero = 'final'
    localStorage.setItem('finalSurveyResult', '')
    this.cmnSerive.setMessage(false)
    this.currentStateQ = 'final'
    this.surveyStarted = survey;
    setTimeout(() => {
      if (this.surveyStarted) {
        this.sendTextAnimStatus = false
        this.testInitialize = false;
        this.surveyModule = true;
        this.questionValue = qL;
        if (this.questionValue == 0) {
          this.question = this.surveyDetails[qL]
          this.currentStateQ = 'initial'
          //this.currentBtnQZero = 'final'
          this.currentBtnAZero = 'final';
          this.currentBtnATZero = 'final';
        }
      }
    }, 100);


      this.delayQZero = 0.5;
      let thInstanceZero = this;
      setTimeout(() => {
        thInstanceZero.sendTextAnimStatus = true;
       (this.currentBtnQZero == 'initial') ? this.currentBtnQZero = 'final' : this.currentBtnQZero = 'initial';
      }, 800)

  }
  getSurveyData(selectedAnswer: number, currentQuestion: any, currentId: number) {
    this.getCurrentId = currentId;
    this.sendTextAnimStatus = true;
    (currentId == 0) ? this.currentStateQZero = 'final' : this.currentStateQZero = 'initial';
    (currentId == 1) ? this.currentStateQOne = 'final' : this.currentStateQOne = 'initial';
    (currentId == 2) ? this.currentStateQTwo = 'final' : this.currentStateQTwo = 'initial';
    (currentId == 3) ? this.currentStateQThree = 'final' : this.currentStateQThree = 'initial';
    (currentId == 4) ? this.currentStateQFour = 'final' : this.currentStateQFour = 'initial';
    (currentId == 5) ? this.currentStateQFive = 'final' : this.currentStateQFive = 'initial';
    (currentId == 6) ? this.currentStateQSix = 'final' : this.currentStateQSix = 'initial';
    (currentId == 7) ? this.currentStateQSeven = 'final' : this.currentStateQSeven = 'initial';
    (currentId == 8) ? this.currentStateQEight = 'final' : this.currentStateQEight = 'initial';
    (currentId == 9) ? this.currentStateQNine = 'final' : this.currentStateQNine = 'initial';
    (currentId == 10) ? this.currentStateQTen = 'final' : this.currentStateQTen = 'initial';

    (currentId == 0) ? this.currentBtnQOne = 'final' : this.currentBtnQOne = 'initial';
    (currentId == 1) ? this.currentBtnQTwo = 'final' : this.currentBtnQTwo = 'initial';
    (currentId == 2) ? this.currentBtnQThree = 'final' : this.currentBtnQThree = 'initial';
    (currentId == 3) ? this.currentBtnQFour = 'final' : this.currentBtnQFour = 'initial';
    (currentId == 4) ? this.currentBtnQFive = 'final' : this.currentBtnQFive = 'initial';
    (currentId == 5) ? this.currentBtnQSix = 'final' : this.currentBtnQSix = 'initial';
    (currentId == 6) ? this.currentBtnQSeven = 'final' : this.currentBtnQSeven = 'initial';
    (currentId == 7) ? this.currentBtnQEight = 'final' : this.currentBtnQEight = 'initial';
    (currentId == 8) ? this.currentBtnQNine = 'final' : this.currentBtnQNine = 'initial';
    (currentId == 9) ? this.currentBtnQTen = 'final' : this.currentBtnQTen = 'initial';



    (currentId == 0) ? this.currentBtnAOne = 'final' : this.currentBtnAOne = 'initial';
    (currentId == 1) ? this.currentBtnATwo  = 'final' : this.currentBtnATwo  = 'initial';
    (currentId == 2) ? this.currentBtnAThree = 'final' : this.currentBtnAThree = 'initial';
    (currentId == 3) ? this.currentBtnAFour = 'final' : this.currentBtnAFour = 'initial';
    (currentId == 4) ? this.currentBtnAFive = 'final' : this.currentBtnAFive = 'initial';
    (currentId == 5) ? this.currentBtnASix = 'final' : this.currentBtnASix = 'initial';
    (currentId == 6) ? this.currentBtnASeven = 'final' : this.currentBtnASeven = 'initial';
    (currentId == 7) ? this.currentBtnAEight = 'final' : this.currentBtnAEight = 'initial';
    (currentId == 8) ? this.currentBtnANine = 'final' : this.currentBtnANine = 'initial';
    (currentId == 9) ? this.currentBtnATen = 'final' : this.currentBtnATen = 'initial';

    (currentId == 0) ? this.currentBtnATZero = 'final' : this.currentBtnATZero = 'initial';

    //buttons show animation
    let thInstance = this;
      if(currentId == 0) {
        this.delayQOne = 0.9;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQOne == 'initial') ? this.currentBtnQOne = 'final' : this.currentBtnQOne = 'initial';
        }, 1250)

      }
      if(currentId == 1) {
        this.delayQTwo = 0.9;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQTwo == 'initial') ? this.currentBtnQTwo = 'final' : this.currentBtnQTwo = 'initial';
        }, 1270)
      }

      if(currentId == 2) {
        this.delayQThree = 0.5;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQThree == 'initial') ? this.currentBtnQThree = 'final' : this.currentBtnQThree = 'initial';
        }, 800)
      }

      if(currentId == 3) {
        this.delayQFour = 0.5;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQFour == 'initial') ? this.currentBtnQFour = 'final' : this.currentBtnQFour = 'initial';
        }, 800)
      }

      if(currentId == 4) {
        this.delayQFive = 0.9;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQFive == 'initial') ? this.currentBtnQFive = 'final' : this.currentBtnQFive = 'initial';
        }, 1270)
      }

      if(currentId == 5) {
        this.delayQSix = 0.9;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQSix == 'initial') ? this.currentBtnQSix = 'final' : this.currentBtnQSix = 'initial';
        }, 1270)
      }

      if(currentId == 6) {
        this.delayQSeven = 0.9;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQSeven == 'initial') ? this.currentBtnQSeven = 'final' : this.currentBtnQSeven = 'initial';
        }, 1270)
      }

      if(currentId == 7) {
        this.delayQEight = 0.5;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQEight == 'initial') ? this.currentBtnQEight = 'final' : this.currentBtnQEight = 'initial';
        }, 800)
      }

      if(currentId == 8) {
        this.delayQNine = 0.5;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQNine == 'initial') ? this.currentBtnQNine = 'final' : this.currentBtnQNine = 'initial';
        }, 800)
      }

      if(currentId == 9) {
        this.delayQTen = 0.6;
        setTimeout(() => {
          thInstance.sendTextAnimStatus = true;
         (this.currentBtnQTen == 'initial') ? this.currentBtnQTen = 'final' : this.currentBtnQTen = 'initial';
        }, 900)
      }


    //end button


    setTimeout(() => {

      this.sendTextAnimStatus = false
      if (this.surveyModule) {
        if (currentId == 10) {
          this.questionValue = currentId + 1;
          this.getpoints.push(this.surveyDetails[currentId].points[selectedAnswer])

          this.getBusinessCiso = this.getTotalPoints(this.getpoints, 'Business_CISO')
          this.getDataStewardCiso = this.getTotalPoints(this.getpoints, 'Data_Steward_CISO')
          this.getCustomerAdvocateCiso = this.getTotalPoints(this.getpoints, 'Customer_Advocate_CISO')
          this.getConnectorCiso = this.getTotalPoints(this.getpoints, 'Connector_CISO')
          this.getDigitalNativeCiso = this.getTotalPoints(this.getpoints, 'Digital_Native_CISO')
          this.anim = 'balloonEffect';

          this.getFinalResultArry = [
            {
              id : 'business-ciso',
              name: 'Business CISO',
              points: this.getBusinessCiso,
              title : 'Business CISO',
              subTitle : 'You are the',
              subsmallTitle : 'Handling security in the boardroom',
              color: '#99B3C2',
              img : './assets/img/Business-CISO-color.gif',
              content:`<p><strong>OVERVIEW</strong><br>This CISO means business, always keeping the organization’s overall objectives and risks top of mind. The Business CISO is an influencer and strategist in the board room, making certain there is alignment between business goals and the cyber program by engaging key stakeholders across the enterprise and communicating cyber-specific needs and goals using business vernacular. </p>
              <p>This CISO is a diplomat and powerful communicator, with the ability to absorb, interpret and contextualize critical security concepts across the organization, keeping stakeholder engagement as a leading priority. The Business CISO is adept at blending traditional and emerging technologies to best serve the company’s changing priorities.</p>
              <p><strong>BLIND SPOT</strong><br>With a constant focus on the business, not much time is left to understand evolving technologies and how those will impact the business and the security function over time.</p>
              <p><strong>KEY ATTRIBUTES</strong><br>Articulate, Polished, Calm</p>
              <p><strong>AS SEEN IN…</strong><br>Financial Services </p>
              <p><strong>BEST FIT</strong><br>Traditional organizations with a no-nonsense Board of Directors</p>
              <p><strong>CISO NEXT PROJECT</strong><br>Establish a formal Business Information Security Officer (BISO) function to better support the business</p>
              <p><strong>FAVORITE BOOK</strong><br><em>How to Win Friends and Influence People</em> by Dale Carnegie</p>
              <p><br></p>`
            },
            {
              id : 'data-steward-ciso',
              name: 'Data Steward CISO',
              points: this.getDataStewardCiso,
              title : 'Data Steward CISO',
              subTitle : 'You are the',
              subsmallTitle : 'Protecting data — our biggest asset',
              color: '#FCCA9C',
              img : './assets/img/Data-Steward-CISO-color.gif',
              content:`<p><strong>OVERVIEW</strong><br>It is all about the data for this CISO, who sees all security efforts through a big-picture, data-driven lens. The Data Steward CISO works diligently to gather, protect, and utilize data in a way that maximizes the organization’s strengths and objectives. This CISO skillfully constructs a “honeycomb” of data, carefully considering the implications of where the data lives, how it is accessed, who in the organization needs to access the data, and when. </p>
              <p>The Data Steward CISO is an analytical leader who bolsters a data-driven mission throughout the enterprise, intimately understands and protects data to support that mission, and consistently evaluates their own metrics to drive the organization’s security forward.</p>
              <p><strong>BLIND SPOT</strong><br>Getting too wrapped up in the data sometimes leads down long and winding paths and may result in missing simple solutions. Don’t “lose the forest through the trees.”</p>
              <p><strong>KEY ATTRIBUTES</strong><br>Analytical, Curious, Persistent</p>
              <p><strong>AS SEEN IN…</strong><br>Healthcare</p>
              <p><strong>BEST FIT</strong><br>Data-driven organizations</p>
              <p><strong>CISO NEXT PROJECT</strong><br>Partnering with Data Privacy Officer to embed security controls into Machine Learning algorithms</p>
              <p><strong>FAVORITE BOOK</strong><br><em>Thinking, Fast and Slow</em> by Daniel Kahneman</p>
              <p><br></p>`
            },
            {
              id : 'customer-advocate-ciso',
              name: 'Customer Advocate CISO',
              points: this.getCustomerAdvocateCiso,
              title : 'Customer Advocate CISO',
              subTitle : 'You are the',
              subsmallTitle : 'Putting the customer first',
              color: '#EDB193',
              img : './assets/img/Customer-Advocate-CISO-color.gif',
              content:`<p><strong>OVERVIEW</strong><br>The Customer Advocate CISO is a community builder, with a keen focus on creating security solutions that bridge customer and business needs. The Customer Advocate CISO understands that customers demand their data be protected. In response, this CISO seeks, develops and implements strategies that proactively improve the customer experience with the organization. </p>
              <p>Like the dolphin, this CISO has a sonar-like ability to identify and envision what the customer will expect before those customer trends develop. The Customer Advocate CISO is both resilient and flexible, understanding that the search for new opportunities to safely handle customer data is best balanced with the need to simultaneously support the company’s evolution into new products and services.</p>
              <p><strong>BLIND SPOT</strong><br>The juggle between ease of use and security is heightened for this CISO. As concessions are made for present ease of use, there is an increase in the load of the customer's future security needs.</p>
              <p><strong>KEY ATTRIBUTES</strong><br>Empathetic, Adaptable, Creative</p>
              <p><strong>AS SEEN IN…</strong><br>Customer Products and Services</p>
              <p><strong>BEST FIT</strong><br>Customer-first organizations</p>
              <p><strong>CISO NEXT PROJECT</strong><br>Customer engagement survey to understand current sentiment and cyber-related concerns</p>
              <p><strong>FAVORITE BOOK</strong><br><em>The Innovator’s Dilemma</em> by Clayton Christensen</p>
              <p><br></p>`
            },
            {
              id : 'connector-ciso',
              name: 'Connector CISO',
              points: this.getConnectorCiso,
              title : 'Connector CISO',
              subTitle : 'You are the',
              subsmallTitle : 'Leveraging partners to buy it, not build it',
              color: '#9CD8E2',
              img : './assets/img/Connector-CISO-color.gif',
              content:`<p><strong>OVERVIEW</strong><br>Successfully bringing people and technologies together is the sweet spot for the Connector CISO. This leader leverages a dynamic organization’s openness to maximize internal and external relationships and is particularly adept at finding the right partner to match the organization’s evolving security needs. </p>
              <p>The Connector CISO recognizes that the ever-changing security landscape has created a skillset shortage that is likely to last well into the future and proactively positions the organization to overcome that skills gap by tapping into their network of potential partners. This CISO is a champion of multi-tasking and enthusiastically articulates a vigorous, collaborative security vision for the organization.</p>
              <p><strong>BLIND SPOT</strong><br>While trying to gain efficiencies, this CISO takes away the company's ability to build tech capabilities. As security risks evolve and the internal resources to fulfill the need don’t exist, this CISO is on the constant search for more funding.</p>
              <p><strong>KEY ATTRIBUTES</strong><br>Outgoing, Driver, Diplomatic</p>
              <p><strong>AS SEEN IN…</strong><br>Private Equity Firms</p>
              <p><strong>BEST FIT</strong><br>Organizations singularly focused on what they do best</p>
              <p><strong>CISO NEXT PROJECT</strong><br>Negotiating an enterprise-wide contract with best-in-class provider for security services</p>
              <p><strong>FAVORITE BOOK</strong><br><em>Shoe Dog</em> by Phil Knight</p>
              <p><br></p>`
            },
            {
              id : 'digital-native-ciso',
              name: 'Digital Native CISO',
              points: this.getDigitalNativeCiso,
              title : 'Digital Native CISO',
              subTitle : 'You are the',
              subsmallTitle : 'Building a better mousetrap',
              color: '#9BD6CF',
              img : './assets/img/Digital-Native-CISO-color.gif',
              content:`<p><strong>OVERVIEW</strong><br>The Digital Native CISO is the builder of the IT and security world, approaching each project with an engineering mindset, always on the lookout for the next security threat. Armed with strong technical expertise, this CISO has been in the trenches and understands how the technologies work, as well as what features must be protected.  </p>
              <p>The Digital Native is continually on the quest to build a comprehensive toolkit of solutions and knows the devil is in the details of any investment. As a collaborator, this CISO believes in continuous improvement and works diligently to learn more about the factors that drive the organization to consistent levels of success. </p>
              <p><strong>BLIND SPOT</strong><br>Sometimes, in the quest for the perfect solution, this CISO forgets about the problem they are trying to solve.</p>
              <p><strong>KEY ATTRIBUTES</strong><br>Particular, Inquisitive, Innovative</p>
              <p><strong>AS SEEN IN…</strong><br>Manufacturing, Startups</p>
              <p><strong>BEST FIT</strong><br>Product-centric organizations focused on scaling securely</p>
              <p><strong>CISO NEXT PROJECT</strong><br>Remediating unauthorized data access issues at a growing IOT start-up</p>
              <p><strong>FAVORITE BOOK</strong><br><em>Elon Musk: Tesla, SpaceX, and the Quest for a Fantastic Future</em> by Ashlee Vance</p>
              <p><br></p>`
            }

          ]

          //Sort to desc value to get higest points
          this.getFinalResultArry = this.getFinalResultArry.sort((a, b) => {
            return b.points - a.points;
          });

          //let maximum = Math.max.apply(Math, this.getFinalResultArry.map(o => o.points));
          this.finalResult = this.getFinalResultArry[0];
          //this.getFinalResultArry.reduce((prev, current) => (prev.points > current.points) ? prev : current)
          this.surveyModule = false;
          this.cmnSerive.setMessage(true);
          localStorage.setItem("finalSurveyResult", JSON.stringify(this.finalResult));
          //window.location.href = this.document.location.origin+'/result'
          this.router.navigate(['/cform']);
        } else {
          this.questionValue = currentId + 1;
          this.getpoints.push(this.surveyDetails[currentId].points[selectedAnswer])
          this.question = this.surveyDetails[this.questionValue]
        }
      }
    }, 100);

  }

  getTotalPoints(arrObjt: any, type: string) {
    return arrObjt.reduce(function (sum: number, item: any) {
      return sum = sum + item[type];
    }, 0);
  }

  startSurveyAgain() {
    localStorage.setItem('finalSurveyResult', '')
    this.cmnSerive.setMessage(false)
    this.sendTextAnimStatus = false;
    this.question = '';
    this.testInitialize = true;
    this.surveyModule = false;
    this.questionValue = -1;
    this.getBusinessCiso = 0;
    this.getDataStewardCiso = 0;
    this.getCustomerAdvocateCiso = 0;
    this.getConnectorCiso = 0;
    this.getDigitalNativeCiso = 0;
    this.getFinalResultArry = [];
    this.finalResult = [];
    this.getpoints = [];

    this.currentStateQ = 'initial';
    this.currentStateQZero = 'initial';
    this.currentStateQOne = 'initial';
    this.currentStateQTwo = 'initial';
    this.currentStateQThree = 'initial';
    this.currentStateQFour = 'initial';
    this.currentStateQFive = 'initial';
    this.currentStateQSix = 'initial';
    this.currentStateQSeven = 'initial';
    this.currentStateQEight = 'initial';
    this.currentStateQNine = 'initial';
    this.currentStateQTen = 'initial';

    this.currentBtnQ = 'final'
    this.currentBtnQZero = 'final';
    this.currentBtnQOne = 'final';
    this.currentBtnQTwo = 'final';
    this.currentBtnQThree = 'final';
    this.currentBtnQFour = 'final';
    this.currentBtnQFive = 'final';
    this.currentBtnQSix = 'final';
    this.currentBtnQSeven = 'final';
    this.currentBtnQEight = 'final';
    this.currentBtnQNine = 'final';
    this.currentBtnQTen = 'final';

    this.currentBtnAZero = 'final';
    this.currentBtnAOne = 'final';
    this.currentBtnATwo = 'final';
    this.currentBtnAThree = 'final';
    this.currentBtnAFour = 'final';
    this.currentBtnAFive = 'final';
    this.currentBtnASix = 'final';
    this.currentBtnASeven = 'final';
    this.currentBtnAEight = 'final';
    this.currentBtnANine = 'final';
    this.currentBtnATen = 'final';
    this.currentBtnATZero = 'final';


    this.startSurvey(true, 0);
  }

  changeState() {
    //this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }
  getEventOnParent(checkAnimFinsihed: any) {
    this.sendTextAnimStatus = checkAnimFinsihed
    this.currentBtnQ = this.currentBtnQ === 'final' ? 'initial' : 'final';
    // setTimeout(() => {
    // this.currentBtnQZero = this.currentBtnQZero === 'final' ? 'initial' : 'final';
    // this.delayQZero = 1;
    // this.timingQZero = 2;
    // },0)

    // setTimeout(() => {
    //   this.currentBtnQOne = this.currentBtnQOne === 'final' ? 'initial' : 'final';
    //   this.delayQOne = 3;
    //   this.timingQOne = 1;
    //   },300)

    // setTimeout(() => {
    //   this.currentBtnQTwo = this.currentBtnQTwo === 'final' ? 'initial' : 'final';
    //   this.delayQTwo = 2;
    //   this.timingQTwo = 1;
    //   },400)


    // setTimeout(() => {
    //   this.currentBtnQThree = this.currentBtnQThree === 'final' ? 'initial' : 'final';
    //   this.delayQThree = 1;
    //   this.timingQThree = 1;
    //   },0)

    //   setTimeout(() => {
    //     this.currentBtnQFour = this.currentBtnQFour === 'final' ? 'initial' : 'final';
    //     this.delayQFour = 1;
    //     this.timingQFour = 2;
    //     },0)


    // setTimeout(() => {
    // this.currentBtnQFive = this.currentBtnQFive === 'final' ? 'initial' : 'final';
    // },500)
    // setTimeout(() => {
    // this.currentBtnQSix = this.currentBtnQSix === 'final' ? 'initial' : 'final';
    // },700)
    // this.currentBtnQSeven = this.currentBtnQSeven === 'final' ? 'initial' : 'final';
    // this.currentBtnQEight = this.currentBtnQEight === 'final' ? 'initial' : 'final';
    // this.currentBtnQNine = this.currentBtnQNine === 'final' ? 'initial' : 'final';
    // this.currentBtnQTen = this.currentBtnQTen === 'final' ? 'initial' : 'final';
    setTimeout(() => {
      this.currentBtnAZero = this.currentBtnAZero === 'final' ? 'initial' : 'final';
      this.currentBtnAOne = this.currentBtnAOne === 'final' ? 'initial' : 'final';
      this.currentBtnATwo  = this.currentBtnATwo === 'final' ? 'initial' : 'final';
      this.currentBtnAThree = this.currentBtnAThree === 'final' ? 'initial' : 'final';
      this.currentBtnAFour =  this.currentBtnAFour === 'final' ? 'initial' : 'final';
      this.currentBtnAFive =  this.currentBtnAFive === 'final' ? 'initial' : 'final';
      this.currentBtnASix =  this.currentBtnASix === 'final' ? 'initial' : 'final';
      this.currentBtnASeven = this.currentBtnASeven === 'final' ? 'initial' : 'final';
      this.currentBtnAEight = this.currentBtnAEight === 'final' ? 'initial' : 'final';
      this.currentBtnANine = this.currentBtnANine === 'final' ? 'initial' : 'final';
      this.currentBtnATen = this.currentBtnATen === 'final' ? 'initial' : 'final';
      //this.currentBtnATZero = this.currentBtnATZero === 'final' ? 'initial' : 'final';
    }, 100);
  }

  getEventOnButtonOne(checkBtnOneText){
  }

  onWindowScroll(event) {
    if (event.target.scrollTop > 50  ) {
           this.title_fixed.nativeElement.style.fontSize = "54px";
           this.subTitle_fixed.nativeElement.style.fontSize = "54px";
        } else {
           this.title_fixed.nativeElement.style.fontSize = "77px";
           this.subTitle_fixed.nativeElement.style.fontSize = "77px";
        }
  }

  shareYourResult(){
    window.location.reload();
    this.showSocialShare = true;
  }

}
