<div class="gradient-bg">
    <div class="container">
        <div class="ab_content">
            <div class="heading_block">
                <h2>ABOUT</h2> <img src="assets/img/about-heading.svg" alt="About Heading">
            </div>
            <div class="content_scroll">
                <div class="content_scroll_inner">
                    <p>In the digital age, CISOs are facing new and evolving changes and challenges at a faster pace than ever before. As a result, CISOs are seeking and pursuing creative, innovative ways to proactively tackle these challenges to protect, prepare and support their organization. </p>
                    <p>CISO Next connects CISOs and security thought leaders to explore and shape how their role will evolve in the current and future business landscape. The CISO Next initiative provides diverse perspectives, innovative tools for collaboration and cross-industry resources that enable security leaders to effectively address the known and unknown challenges in a dynamic threat landscape.</p>
                    <p>By engaging with CISO Next, CISOs are able to:</p>
                    <ul>
                    <li>Network and collaborate with fellow CISOs – from similar industries and/or local geographies</li>
                    <li>Acquire innovative methods to tackle current challenges and build solutions for the future</li>
                    <li>Expand their toolbox by gaining insight into different CISO personas and approaches</li>
                    <li>Tap into a knowledge sharing community</li>
                    </ul>

                    <p>Learn more about CISO Next and explore our resources: </p>
                    <p class="text-center"><a href="https://www.protiviti.com/US-en/cisonext" class="btnSurvey btn-blue-outline btn" target="_blank">Learn more</a></p>

                </div>
            </div>
        </div>
    </div>
</div>
