<div class="gradient-bg">
      <div class="ab_content ciso-form-wrapper">
          <div class="ciso-form-box">
              <div class="ciso-form-box-wrap">
                  <div class="ciso-form-box-inner">
              <div class="heading_block">
                  <h2>To find out your CISO type: </h2>
              </div>
              <section fxLayout="row wrap" fxLayoutAlign="center center">
                  <mat-card fxFlex="500px" fxFlex.xs="100%">
                      <mat-card-content>
                        <form [formGroup]="customerForm" autocomplete="off" novalidate (ngSubmit)="getCustomer(customerForm.value)" fxLayout="column wrap"
                            fxLayoutAlign="center center" fxLayoutGap="10px">
                        <mat-card-content>
                          <mat-form-field>
                            <input matInput type="text" placeholder="First &nbsp;  Name" formControlName="firstName" id="firstName">

                            <mat-error *ngIf="hasError('firstName', 'required')">First Name is required</mat-error>
                            <mat-error *ngIf="hasError('firstName', 'maxlength')">You have more than 60 characters</mat-error>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput type="text" placeholder="Last &nbsp; Name" formControlName="lastName" id="lastName">

                            <mat-error *ngIf="hasError('lastName', 'required')">Last Name is required</mat-error>
                            <mat-error *ngIf="hasError('lastName', 'maxlength')">You have more than 60 characters</mat-error>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput type="text" placeholder="title" formControlName="title" id="title">

                            <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
                            <mat-error *ngIf="hasError('title', 'maxlength')">You have more than 60 characters</mat-error>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput type="text" placeholder="Company" formControlName="company" id="company">

                            <mat-error *ngIf="hasError('company', 'required')">Company is required</mat-error>
                            <mat-error *ngIf="hasError('company', 'maxlength')">You have more than 60 characters</mat-error>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput type="text" placeholder="Email" formControlName="email" id="email">

                            <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
                            <mat-error *ngIf="hasError('email', 'email')">Enter valid email address</mat-error>
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Please &nbsp; select</mat-label>
                          <mat-select formControlName="selectedCountry">


                            <mat-option *ngFor="let country of countries" [value]="country">
                              {{country.value}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="hasError('selectedCountry', 'required')">Country is required</mat-error>
                        </mat-form-field>
                          <br/>
                          <br/>
                          <br/>
                          <div class="privacy-content">
                            By completing the CISO Next Survey, I understand and agree that Protiviti will process contact details for the purposes of organizing and administering this survey. Contact information may be used to communicate with you regarding this survey along with providing you information tied to the CISO Next initiative, as well as relevant goods and services, which may be of interest to you in connection with the survey.
                          </div>
                          <div class="terms-check">
                            <mat-checkbox formControlName="uAagree" >I have read and agree to the above Protiviti Policy & Terms of Use * </mat-checkbox>
                            <div *ngIf="customerForm.hasError('required', 'uAagree')" class="invalid-feedback">* Accept Ts & Cs is required</div>
                          </div>




                          <div class="terms-check">
                            <mat-checkbox formControlName="rAagree">Please sign me up for Protiviti marketing communications</mat-checkbox>
                          </div>


                            <!-- <mat-checkbox formControlName="uAagree">
                              By submitting this form, I understand and agree that Protiviti will process contact details for the purposes of organizing and administering this event.
                              <br/>Protiviti will communicate with you regarding this event along with providing you information and relevant goods and services,
                              <br/> which may be of interest to you in connection with the event topic.</mat-checkbox>
                            <mat-checkbox formControlName="rAagree">I have read and agree to Protiviti's Privacy Policy and Terms of Use</mat-checkbox> -->


                        </mat-card-content>
                        <mat-card-actions class="form-button">
                          <button mat-raised-button color="primary" [disabled]="customerForm.invalid"><img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2987172&conversionId=3905892&fmt=gif" />Submit</button>
                        </mat-card-actions>


                      </form>
                      </mat-card-content>
                      <mat-card-actions>

                      </mat-card-actions>
                    </mat-card>
                </section>
                  </div>
              </div>
          </div>

      </div>
</div>
