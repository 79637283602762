import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import { FlexLayoutModule } from "@angular/flex-layout";

import { HomeComponent } from './component/home/home.component';
import { AboutComponent } from './component/about/about.component';
import { TypesComponent } from './component/types/types.component';
import { TypeDetailsComponent } from './component/type-details/type-details.component';

import { TextAnimationDirective } from './directive/text-animation.directive';
import { BtntxtAnimationDirective } from './directive/btntxt-animation.directive';
import { TitletxtAnimationDirective } from './directive/titletxt-animation.directive';
import { SocialshareDirective } from './directive/socialshare.directive';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CommonserviceService } from './component/service/commonservice.service';
import { SeoserviceService } from './component/service/seoservice.service';

import { TeximateModule } from 'ngx-teximate';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  HttpClientModule } from '@angular/common/http';
import { ResultComponent } from './component/result/result.component';
import { CformComponent } from './component/cform/cform.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    TypesComponent,
    TypeDetailsComponent,
    TextAnimationDirective,
    BtntxtAnimationDirective,
    TitletxtAnimationDirective,
    SocialshareDirective,
    ResultComponent,
    CformComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    TeximateModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],

  providers: [
    CommonserviceService,
    SeoserviceService,
    {
      provide: LocationStrategy, useClass: HashLocationStrategy,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
