import { Component, OnInit, Inject  } from '@angular/core';
import { Router,NavigationStart,ActivatedRoute,NavigationEnd  } from '@angular/router';
import { Location } from '@angular/common';
import { trigger, state, style, animate, transition,useAnimation } from '@angular/animations';
import { LocationStrategy } from "@angular/common";
import { CommonserviceService } from './component/service/commonservice.service'
import { SeoserviceService } from './component/service/seoservice.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { bounce,
  flash,
  pulse,
  rubberBand,
  shake,
  swing,
  tada,
  wobble,
  jello,
  bounceIn,
  bounceInDown,
  bounceInLeft,
  bounceInRight,
  bounceInUp,
  bounceOut,
  bounceOutDown,
  bounceOutLeft,
  bounceOutRight,
  bounceOutUp,
  fadeIn,
  fadeInDown,
  fadeInUp,
  fadeInLeft,
  fadeInRight,
  fadeOut,
  fadeOutDown,
  fadeOutUp,
  fadeOutLeft,
  fadeOutRight,
  slideInDown,
  slideInUp,
  slideInLeft,
  slideInRight,
  slideOutDown,
  slideOutUp,
  slideOutLeft,
  slideOutRight,
  flip,
  flipInX,
  flipInY,
  flipOutX,
  flipOutY,
  lightSpeedIn,
  lightSpeedOut,
  rotateIn,
  rotateInDownLeft,
  rotateInDownRight,
  rotateInUpLeft,
  rotateInUpRight,
  rotateOut,
  rotateOutDownLeft,
  rotateOutDownRight,
  rotateOutUpLeft,
  rotateOutUpRight,
  hinge,
  jackInTheBox,
  rollIn,
  rollOut,
  zoomIn,
  zoomInDown,
  zoomInUp,
  zoomInLeft,
  zoomInRight,
  zoomOut,
  zoomOutDown,
  zoomOutUp,
  zoomOutLeft,
  zoomOutRight } from 'ng-animate';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        transform: 'scale(1)'
      })),
      transition('void <=> *', animate(2000)),
    ]),
    trigger('logo',[transition('* => *', useAnimation(fadeIn))]),
    trigger('menu',[transition('* => *', useAnimation(jackInTheBox))]),
    trigger('social',[transition('* => *', useAnimation(jackInTheBox))]),
    trigger('nav-drop-down',[transition('* => *', useAnimation(flipInX))]),

  ]
})
export class AppComponent implements OnInit{
  title:string = 'Protiviti'
  menuArray:Array<{title: string, url: string}> = [];
  routeClass: boolean = false;
  routeabClass: boolean = false;
  routetyDetailsClass: boolean = false;
  routeResultClass:boolean = false;
  getPageUrl:any;
  emailstring:any;
  linkedInUrl:any;
  twitterUrl:any;
  showmenu:boolean=false;
  pageName:any;
  constructor(private seoService : SeoserviceService,private activatedRoute: ActivatedRoute,private titleService: Title, location: Location, router: Router,private locationStrategy: LocationStrategy,private cmnSerive: CommonserviceService){
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      this.seoService.updateTitle(seoData['title']);
      //this.seoService.updateMetaTags(seoData['metaTags']);
    });

    router.events.subscribe((val) => {
      if(val instanceof NavigationStart) {
      this.getPageUrl = val.url.split("/").pop();
      this.pageName = this.getPageUrl;
      this.titleService.setTitle('Protiviti | '+this.getPageUrl.charAt(0).toUpperCase() + this.getPageUrl.slice(1));
        if(this.getPageUrl == 'survey' ||  this.getPageUrl == ''){
          if(this.routeResultClass  == true){
          this.routeClass = false;
          this.routeabClass = false;
          this.routetyDetailsClass = false;
          this.routeResultClass = true;
          }
          this.routeClass = false;
          this.routeabClass = false;
          this.routetyDetailsClass = false;
          this.routeResultClass = false;
        } else if(this.getPageUrl == 'types'){
          this.routeClass = true;
          this.routeabClass = false;
          this.routetyDetailsClass = false;
          this.routeResultClass = false;
        } else if(this.getPageUrl == 'about'){
          this.routeabClass = true;
          this.routeClass = false
          this.routetyDetailsClass = false;
          this.routeResultClass = false;
        } else if(this.getPageUrl == 'cform'){
          this.routeabClass = true;
          this.routeClass = false
          this.routetyDetailsClass = false;
          this.routeResultClass = false;
        }else {
          this.routetyDetailsClass = true;
          this.routeabClass = false;
          this.routeClass = false;
          this.routeResultClass = false;
        }
      }

    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.emailstring= "mailto:xyz@example.com?Subject=Hello&body=links:  %0Dhttp://link1.com  %0D http://link1.com";
    this.emailstring= "mailto:xyz@example.com?Subject=Contact us&body=Hi,  %0DWrite your queries here  %0D Thanks";
    this.linkedInUrl = 'https://www.linkedin.com/'
    this.twitterUrl = 'https://twitter.com/?lang=en'
    this.menuArray = [{
      title : 'Take the Survey',
      url:'survey',
    },{
      title : 'Ciso Types',
      url:'types',
    },{
      title : 'About',
      url:'about',
    },
  ]

  this.cmnSerive.getMessage.subscribe(res => {
    this.routeResultClass = res
  });

  }

    status: boolean = false;
    clickEventMenu(){
        this.status = !this.status;
        this.showmenu = !this.showmenu;
    }

}
