import { Directive, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router,NavigationStart,ActivatedRoute,NavigationEnd  } from '@angular/router';

@Directive({
  selector: '[socialShare]'
})

export class SocialshareDirective implements AfterViewInit {
  @Output() onCheckLetters = new EventEmitter();
  emailstring= "mailto:?Subject=What CISO type are you?&body=Hi, %0D  %0D As the challenges of the digital age change and evolve, so must the role of the CISO. As such, CISOs should %0D leverage their own characteristics to proactively protect, prepare and support their organization for continued %0D success. %0D %0D What type of CISO are you?  %0D %0D Take the Survey: https://www.protiviti.com/cisotype to find out";
  constructor(private eleRef: ElementRef) {

    //eleRef.nativeElement.style.background = 'red';
  }

  ngAfterViewInit(): void {

    this.eleRef.nativeElement.innerHTML = `

    <ul>
        <li><a href="${this.emailstring}"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.24 17.3"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M363.12,318.21H341.57V304l11.3,10,11.3-10v13.1h1.19V302.35h-25v17h22.74Zm-20.06-14.68h19.62l-9.81,8.8Z" transform="translate(-340.38 -302.35)"/><path class="cls-1" d="M363.91,318.8a.86.86,0,0,0,.86.85.85.85,0,1,0,0-1.7A.86.86,0,0,0,363.91,318.8Z" transform="translate(-340.38 -302.35)"/></svg></a></li>
        <li><a data-sharer="linkedin" data-url="https://cisotype.protiviti.com"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.04 28.04"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M183,306.91a2.14,2.14,0,1,1,2.35-2.12A2.24,2.24,0,0,1,183,306.91Zm0-3.06a1,1,0,1,0,1.17.94A1.07,1.07,0,0,0,183,303.85Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M188,325a13.9,13.9,0,0,0,8.58-2.93l-.73-.94a12.81,12.81,0,1,1,2.34-2.35l.94.72A13.87,13.87,0,0,0,202,311a14,14,0,1,0-14,14Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M196.88,319.81a.86.86,0,0,0,1.21,1.21.86.86,0,1,0-1.21-1.21Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M185.33,316.71v-9.45h-4.47v9.45ZM182,308.45h2.1v7.08H182Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M196.2,316.71V311a3.55,3.55,0,0,0-3.69-3.87,3.81,3.81,0,0,0-2.24.65l0-.46h-3.85v9.38h4.32v-4c0-1.84.48-1.84.68-1.84s.51,0,.54,1.34v4.55Zm-4.82-7.08c-1.86,0-1.86,2.28-1.86,3v2.87h-2v-7h1.58l.18,2.14a6.58,6.58,0,0,1,.42-1.54c.52-.85,1.8-.79,2.77-.79A2.4,2.4,0,0,1,195,311v4.52h-1.91v-3.39C193.09,311.52,193.05,309.63,191.38,309.63Z" transform="translate(-173.98 -296.98)"/></svg></a></li>
        <li><a data-sharer="twitter" data-title="What CISO type are you?" data-url="https://cisotype.protiviti.com/"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.04 28.04"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M261,483a13.9,13.9,0,0,0,8.58-2.93l-.73-.94a12.81,12.81,0,1,1,2.34-2.35l.94.72A13.87,13.87,0,0,0,275,469a14,14,0,1,0-14,14Z" transform="translate(-246.98 -454.98)"/><circle class="cls-1" cx="23.51" cy="23.43" r="0.86"/><path class="cls-1" d="M270.05,464.63a.79.79,0,0,0-.2-.62,1,1,0,0,0-.26-.22,1,1,0,0,0-.14-.89.79.79,0,0,0-.53-.38,1.21,1.21,0,0,0-.86.2,3.9,3.9,0,0,1-1,.41,4,4,0,0,0-6.45,2.74,7.84,7.84,0,0,1-4.3-2.41,1.25,1.25,0,0,0-1.11-.6,1,1,0,0,0-.74.52,4,4,0,0,0-.19,3.42,1.16,1.16,0,0,0-.3.91,4,4,0,0,0,1.15,2.58,1.58,1.58,0,0,0,.16,1,4.18,4.18,0,0,0,1.21,1.45,5.81,5.81,0,0,1-1.78.21,1.27,1.27,0,0,0-1,.2l-.05,0,0,.06a1,1,0,0,0,.11,1.3,7.1,7.1,0,0,0,2.63,1.25,9.76,9.76,0,0,0,2.72.39,9.65,9.65,0,0,0,6.5-2.57,9.54,9.54,0,0,0,3.06-7C269.83,465.52,270,465,270.05,464.63Zm-2.46,1.29-.24.2,0,.3a8.42,8.42,0,0,1-2.68,6.38l0,0a8.54,8.54,0,0,1-8.05,1.88h0a9.61,9.61,0,0,1-1.43-.52,6.5,6.5,0,0,0,3-.85l1.18-.76-1.36-.31a3,3,0,0,1-1.69-1.47.53.53,0,0,1-.05-.15l.74-.42-.81-.54a2.78,2.78,0,0,1-1-1.87l1.61.5-1.15-1.49a2.71,2.71,0,0,1-.24-2.61,8.9,8.9,0,0,0,5.75,2.93l.76.08-.11-.75a2.86,2.86,0,0,1,1.51-2.73,2.83,2.83,0,0,1,3.23.49l.22.19.29-.06a7.29,7.29,0,0,0,1.14-.36l-.1.16-1,1.58,1.24-.53C268.16,465.4,267.92,465.63,267.59,465.92Z" transform="translate(-246.98 -454.98)"/></svg></a></li>
    </ul>
    `;


  }

  txtAnim() {
    //this.onCheckBtnLetters.emit(false);
  }

}


