import { Directive, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
declare var anime: any;

@Directive({
  selector: '[titleTxtAnimation]'
})

export class TitletxtAnimationDirective implements AfterViewInit {

  @Output() onCheckLetters = new EventEmitter();
  animFinished:boolean = false;
  aText: any = [];
  crtCls:any;
  lr:any = Math.random().toString(36).substring(7);


  constructor(private eleRef: ElementRef) {

    //eleRef.nativeElement.style.background = 'red';
  }

  ngAfterViewInit(): void {
    this.aText = this.eleRef.nativeElement.textContent;
    this.crtCls = `<span class='${this.lr}'>$&</span>`
    this.eleRef.nativeElement.innerHTML = this.aText.replace(/\S/g,  this.crtCls);
    let inst = this
    anime.timeline({loop: false})
  .add({
    targets: `.an-1 .${this.lr}`,
    scale: [4,1],
    opacity: [0,1],
    translateZ: 0,
    easing: "easeOutExpo",
    duration: 950,
    delay: (el:any, i:any) => 150*i,
    begin: function(anim:any) {
    },
    complete: function(anim:any) {
      inst.onCheckLetters.emit(true);
    }
  });
    //this.txtAnim();


    //this.eleRef.nativeElement.innerHTML = 'vijai'
  }

  txtAnim() {
    //this.onCheckBtnLetters.emit(false);




  }

}


