<div class="homeBlock">
  <div class="container"
       fxLayout
       fxLayout.xs="column"
       fxLayoutAlign="center"
       fxLayoutGap="0px"
       fxLayoutGap.xs="0">

    <div class="item"
    fxFlex
    fxFlexOffset.xs="0"
    >
        <div *ngIf="testInitialize && !surveyModule" class="home_animations">
            <div class="h_animate">
                <img src="./assets/img/landing-animation.gif" alt="" class="desktop_animation">
                <img src="./assets/img/landing_animation_mobile.gif" alt="" class="mobile_animation">
            </div>
            <!--div class="h_animate h_animate1" *ngIf="getHomeAnimationValue=='animationHomeOne'">
                <img src="./assets/img/home-gif1.gif" alt="">
            </div>
            <div class="h_animate h_animate2" *ngIf="getHomeAnimationValue=='animationHomeTwo'">
                <img src="./assets/img/home-gif2.gif" alt="">
            </div>
            <div class="h_animate h_animate3" *ngIf="getHomeAnimationValue=='animationHomeThree'">
                <img src="./assets/img/home-gif3.gif" alt="">
            </div>
            <div class="h_animate h_animate4" *ngIf="getHomeAnimationValue=='animationHomeFour'">
                <img src="./assets/img/home-gif4.gif" alt="">
            </div>
            <div class="h_animate h_animate5" *ngIf="getHomeAnimationValue=='animationHomeFive'">
                <img src="./assets/img/home-gif5.gif" alt="">
            </div-->
        </div>
    <div>

      <div  *ngIf="testInitialize && !surveyModule" class="centerContent" [@changeDivSize] = 'currentStateQ'>


        <!-- <h1 btnAnimation class="an-1" (onCheckLetters)="getEventOnParent($event)" [@fadeInOut]>What CISO type are you?</h1>

        <div fxLayoutAlign="center center" class="example-button-row" [@changeDivSize] = 'currentBtnQ'>

         <button  btnAnimation mat-stroked-button color="primary" class="an-1 btnSurvey btn btn-blue-outline" [@fadeInOut] (click)="startSurvey(true,0)">
         Take the survey
        </button> -->

        <!-- <h1 textAnimation (onCheckLetters)="getEventOnParent($event)" [@fadeInOut] > -->
          <h1><teximate [text]="'What CISO type are you?'" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h1>

        <div fxLayoutAlign="center center" class="example-button-row" [@changeDivSize] = 'currentBtnQ'>
          <button  *ngIf='!sendTextAnimStatus' mat-stroked-button color="primary" class="an-1 btnSurvey btn btn-blue-outline">
            Take the survey
           </button>
          <button mat-stroked-button color="primary" class="an-1 btnSurvey btn btn-blue-outline"  [@qStart]="{ value: 'fadeIn', params: { timing: 1 || 5, delay: 1 || 2 } }" (click)="startSurvey(true,0)">
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2987172&conversionId=3905908&fmt=gif"/>Take the survey
          </button>
      </div>
      </div>
      <div *ngIf="surveyModule && question.id == 0" [@changeDivSize] = 'currentStateQZero'>

        <!-- <app-questionone [getDataFromHome]="question"></app-questionone> -->
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>


        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQZero'>

        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="an-1 btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: 1 || 5, delay: delayQZero || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="an-1 btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: 1 || 5, delay: delayQZero || 2 } }" (click)="getSurveyData(1,question,question.id)" >{{question.answer[1].qTwo}}</button>
      </div></div>
      <div *ngIf="surveyModule && question.id == 1" [@changeDivSize] = 'currentStateQOne'>
        <h3>
          <teximate [text]="question.question" [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQOne'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"   [@qFadein]="{ value: 'fadeIn', params: { timing: timingQOne || 5, delay: delayQOne || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQOne || 5, delay: delayQOne || 2 } }" (click)="getSurveyData(1,question,question.id)" >{{question.answer[1].qTwo}}</button><!--[@changeDivSize] = 'currentBtnAOne'-->
      </div>
    </div>
      <div *ngIf="surveyModule && question.id == 2"  [@changeDivSize] = 'currentStateQTwo'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQTwo'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQTwo || 5, delay: delayQTwo || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQTwo || 5, delay: delayQTwo || 2 } }" (click)="getSurveyData(1,question,question.id)" >{{question.answer[1].qTwo}}</button>
      </div>
    </div>
      <div  *ngIf="surveyModule && question.id == 3" [@changeDivSize] = 'currentStateQThree'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQThree'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQThree || 5, delay: delayQThree || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQThree || 5, delay: delayQThree || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
    </div>
      <div *ngIf="surveyModule && question.id == 4" [@changeDivSize] = 'currentStateQFour'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQFour'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQFour || 5, delay: delayQFour || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQFour || 5, delay: delayQFour || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>

      <div *ngIf="surveyModule && question.id == 5" [@changeDivSize] = 'currentStateQFive'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQFive'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQFive || 5, delay: delayQFive || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQFive || 5, delay: delayQFive || 2 } }"(click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>

      <div *ngIf="surveyModule && question.id == 6" [@changeDivSize] = 'currentStateQSix'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQSix'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQSix || 5, delay: delayQSix || 2 } }"  (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQSix || 5, delay: delayQSix || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>
      <div *ngIf="surveyModule && question.id == 7" [@changeDivSize] = 'currentStateQSeven'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQSeven'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQSeven || 5, delay: delayQSeven || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQSeven || 5, delay: delayQSeven || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>
      <div *ngIf="surveyModule && question.id == 8" [@changeDivSize] = 'currentStateQEight'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQEight'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQEight || 5, delay: delayQEight || 2 } }"  (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQEight || 5, delay: delayQEight || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>
      <div *ngIf="surveyModule && question.id == 9" [@changeDivSize] = 'currentStateQNine'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQNine'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQNine || 5, delay: delayQNine || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQNine || 5, delay: delayQNine || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>
      <div *ngIf="surveyModule && question.id == 10" [@changeDivSize] = 'currentStateQTen'>
        <h3>
          <teximate [text]="question.question" (finish)="getEventOnParent($event)"  [enter]="enterAnimation"></teximate>
        </h3>
        <div class="example-button-row hafl-width-btn" fxLayoutAlign="center center" [@changeDivSize] = 'currentBtnQTen'>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white"  [@qFadein]="{ value: 'fadeIn', params: { timing: timingQTen || 5, delay: delayQTen || 2 } }" (click)="getSurveyData(0,question,question.id)">{{question.answer[0].qOne}}</button>
        <button [disabled]="!sendTextAnimStatus" mat-stroked-button color="primary" class="btnSurvey btn btn-white" [@qFadein]="{ value: 'fadeIn', params: { timing: timingQTen || 5, delay: delayQTen || 2 } }" (click)="getSurveyData(1,question,question.id)">{{question.answer[1].qTwo}}</button>
      </div>
      </div>
    </div>

    </div>
  </div>
  <!-- <div *ngIf="!testInitialize && !surveyModule"> -->

      <!-- <div class="type-details">
          <div class="typecolumn typecolumn_content typecolumn_bg">
              <div class="typecolumn_inner_content">
                <h1 #subTitle_fixed>{{finalResult.subTitle}}</h1>
                <div class="fixed" >

                  <h1 #title_fixed>{{finalResult.title}}</h1>
               </div>
                  <div class="typeContent_scroll">
                      <div class="typeContent_scroll_inner" (scroll)="onWindowScroll($event)">

                          <h4 [ngStyle]="{'color': finalResult.color}">{{finalResult.subsmallTitle}}</h4>


                          <div class="typeContent" [innerHTML]="finalResult.content"></div>
                          <div class="shadowContent"></div>
                          <div class="resultShare">

                              <div class="surveyBtn_wrap">
                                  <div class="surveyBtn_inner">
                                    <button  *ngIf="!showSocialShare" class="btnSurvey btn btn-blue-outline"  mat-stroked-button color="primary" (click)="shareYourResult()">Share your results</button>
                                    <div ngClass="socialLinks" *ngIf="showSocialShare">
                                        <ul>
                                            <li><a [href]=emailstring><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.24 17.3"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M363.12,318.21H341.57V304l11.3,10,11.3-10v13.1h1.19V302.35h-25v17h22.74Zm-20.06-14.68h19.62l-9.81,8.8Z" transform="translate(-340.38 -302.35)"/><path class="cls-1" d="M363.91,318.8a.86.86,0,0,0,.86.85.85.85,0,1,0,0-1.7A.86.86,0,0,0,363.91,318.8Z" transform="translate(-340.38 -302.35)"/></svg></a></li>
                                            <li><a data-sharer="linkedin" data-url="https://ellisonleao.github.io/sharer.js/"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.04 28.04"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M183,306.91a2.14,2.14,0,1,1,2.35-2.12A2.24,2.24,0,0,1,183,306.91Zm0-3.06a1,1,0,1,0,1.17.94A1.07,1.07,0,0,0,183,303.85Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M188,325a13.9,13.9,0,0,0,8.58-2.93l-.73-.94a12.81,12.81,0,1,1,2.34-2.35l.94.72A13.87,13.87,0,0,0,202,311a14,14,0,1,0-14,14Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M196.88,319.81a.86.86,0,0,0,1.21,1.21.86.86,0,1,0-1.21-1.21Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M185.33,316.71v-9.45h-4.47v9.45ZM182,308.45h2.1v7.08H182Z" transform="translate(-173.98 -296.98)"/><path class="cls-1" d="M196.2,316.71V311a3.55,3.55,0,0,0-3.69-3.87,3.81,3.81,0,0,0-2.24.65l0-.46h-3.85v9.38h4.32v-4c0-1.84.48-1.84.68-1.84s.51,0,.54,1.34v4.55Zm-4.82-7.08c-1.86,0-1.86,2.28-1.86,3v2.87h-2v-7h1.58l.18,2.14a6.58,6.58,0,0,1,.42-1.54c.52-.85,1.8-.79,2.77-.79A2.4,2.4,0,0,1,195,311v4.52h-1.91v-3.39C193.09,311.52,193.05,309.63,191.38,309.63Z" transform="translate(-173.98 -296.98)"/></svg></a></li>
                                            <li><a data-sharer="twitter" data-title="Checkout Sharer.js!" data-hashtags="awesome, sharer.js" data-url="https://ellisonleao.github.io/sharer.js/"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.04 28.04"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M261,483a13.9,13.9,0,0,0,8.58-2.93l-.73-.94a12.81,12.81,0,1,1,2.34-2.35l.94.72A13.87,13.87,0,0,0,275,469a14,14,0,1,0-14,14Z" transform="translate(-246.98 -454.98)"/><circle class="cls-1" cx="23.51" cy="23.43" r="0.86"/><path class="cls-1" d="M270.05,464.63a.79.79,0,0,0-.2-.62,1,1,0,0,0-.26-.22,1,1,0,0,0-.14-.89.79.79,0,0,0-.53-.38,1.21,1.21,0,0,0-.86.2,3.9,3.9,0,0,1-1,.41,4,4,0,0,0-6.45,2.74,7.84,7.84,0,0,1-4.3-2.41,1.25,1.25,0,0,0-1.11-.6,1,1,0,0,0-.74.52,4,4,0,0,0-.19,3.42,1.16,1.16,0,0,0-.3.91,4,4,0,0,0,1.15,2.58,1.58,1.58,0,0,0,.16,1,4.18,4.18,0,0,0,1.21,1.45,5.81,5.81,0,0,1-1.78.21,1.27,1.27,0,0,0-1,.2l-.05,0,0,.06a1,1,0,0,0,.11,1.3,7.1,7.1,0,0,0,2.63,1.25,9.76,9.76,0,0,0,2.72.39,9.65,9.65,0,0,0,6.5-2.57,9.54,9.54,0,0,0,3.06-7C269.83,465.52,270,465,270.05,464.63Zm-2.46,1.29-.24.2,0,.3a8.42,8.42,0,0,1-2.68,6.38l0,0a8.54,8.54,0,0,1-8.05,1.88h0a9.61,9.61,0,0,1-1.43-.52,6.5,6.5,0,0,0,3-.85l1.18-.76-1.36-.31a3,3,0,0,1-1.69-1.47.53.53,0,0,1-.05-.15l.74-.42-.81-.54a2.78,2.78,0,0,1-1-1.87l1.61.5-1.15-1.49a2.71,2.71,0,0,1-.24-2.61,8.9,8.9,0,0,0,5.75,2.93l.76.08-.11-.75a2.86,2.86,0,0,1,1.51-2.73,2.83,2.83,0,0,1,3.23.49l.22.19.29-.06a7.29,7.29,0,0,0,1.14-.36l-.1.16-1,1.58,1.24-.53C268.16,465.4,267.92,465.63,267.59,465.92Z" transform="translate(-246.98 -454.98)"/></svg></a></li>
                                        </ul>
                                    </div>
                                  </div>
                                  <div class="surveyBtn_inner">
                                    <button class="btnSurvey btn btn-blue-outline"  mat-stroked-button color="primary" [@fadeInOut] (click)="startSurveyAgain()">Take another survey</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="typecolumn typecolumn_img">
              <div class="typecolumn_inner_img" [@image]>
                  <img src={{finalResult.img}}  alt={{finalResult.title}}>
              </div>
          </div>
          <div class="clearfix"></div>
      </div>
  </div> -->
  <div class="survayDots">
  <div class="container"
       fxLayout
       fxLayout.xs="column"
       fxLayoutAlign="center"
       fxLayoutGap="0px"
       fxLayoutGap.xs="0"
  >

  <div class="item item-1"
  fxFlex
  fxFlexOffset.xs="0"
  >

  <div
  fxFlex
  fxFlexFill
  fxLayout="column"
  fxLayoutAlign="center center"
  *ngIf="surveyModule">
    <ul class="carousel-slide-toggle">
      <li *ngFor="let n of [0,1,2,3,4,5,6,7,8,9,10]" [ngClass]="{'current': question.id === n}"><a>{{n}}</a></li>

  </ul>
  </div>
  </div>

  </div></div>
  </div>
