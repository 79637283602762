import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './component/home/home.component';
import { AboutComponent } from './component/about/about.component';
import { TypesComponent } from './component/types/types.component';
import { TypeDetailsComponent } from './component/type-details/type-details.component';
import { ResultComponent } from './component/result/result.component';
import { CformComponent } from './component/cform/cform.component';

const routes: Routes = [
  { path: '', redirectTo: 'survey', pathMatch: 'full' },
  { path: 'protiviti', redirectTo: 'survey', pathMatch: 'full' },
  {
      path: 'survey',
      component: HomeComponent,
      data: {
        seo: {
          title: 'Protiviti | Home'
        }
      }
  },
  {
      path: 'about',
      component: AboutComponent,
      data: {
        seo: {
          title: 'Protiviti | About Us'
        }
      }
  },
  {
    path: 'result',
    component: ResultComponent,
    data: {
      seo: {
        title: 'Protiviti | Result'
      }
    }
  },
  {
      path: 'types',
      component: TypesComponent,
      data: {
        seo: {
          title: 'Protiviti | CISO Type'
        }
      }
  },
  {
      path: 'type-details/:type',
      component: TypeDetailsComponent,
      data: {
        seo: {
          title: 'Protiviti | CISO Type Details'
        }
      }
  },
  {
    path: 'cform',
    component:CformComponent,
    data: {
      seo: {
        title: 'Protiviti | Contact Form'
      }
    }
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
