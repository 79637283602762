import { Injectable,EventEmitter } from '@angular/core';
import { Observable, Subject,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  private subject = new BehaviorSubject<any>(false);
  private titleLoading = new BehaviorSubject<any>(false);
  constructor() { }

  get getMessage() {
    return this.subject.asObservable();
  }

  get getTitleLoadingStatus(){
    return this.titleLoading.asObservable();
  }

clearMessages() {
    this.subject.next('');
}

clearTitleLoading() {
  this.titleLoading.next('');
}
setMessage(status:any){
this.subject.next(status)
}

setTitleLoading(status:any){
  this.titleLoading.next(status)
  }


}
