import { Directive, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
@Directive({
  selector: '[textAnimation]'
})


export class TextAnimationDirective implements AfterViewInit {
  @Output() onCheckLetters = new EventEmitter();
  @Output() onCheckTitle = new EventEmitter();
  animFinished:boolean = false;
  aText: any = [];
  iSpeed: number = 100
  iIndex: number = 0
  iArrLength: number = 0
  iScrollAt: number = 20
  iTextPos: number = 0
  sContents: string = ''
  iRow: any;
  destination: string = ''

  constructor(private eleRef: ElementRef) {

    //eleRef.nativeElement.style.background = 'red';
  }

  ngAfterViewInit() {
    this.aText = [this.eleRef.nativeElement.textContent]
    this.txtAnim();


    //this.eleRef.nativeElement.innerHTML = 'vijai'
  }

  txtAnim() {
    //this.onCheckLetters.emit(false);
    this.iArrLength = this.eleRef.nativeElement.textContent.length
    this.sContents = ' ';
    this.iRow = Math.max(0, this.iIndex - this.iScrollAt);
    //this.destination = this.eleRef.nativeElement.innerHTML;

    while (this.iRow < this.iIndex) {
      this.sContents += this.aText[this.iRow++] + '<br />';
    }


    this.eleRef.nativeElement.innerHTML = '<span>'+this.sContents + this.aText[this.iIndex].substring(0, this.iTextPos)+'</span>' + " ";
    if (this.iTextPos++ == this.iArrLength) {
      this.iTextPos = 0;
      this.iIndex++;
      if (this.iIndex != this.aText.length) {
        this.iArrLength = this.aText[this.iIndex].length;
        setTimeout(() => {
          this.txtAnim()
        }, 500);
      }
      this.onCheckLetters.emit(true);
      this.onCheckTitle.emit(true);
    } else {
      setTimeout(() => {
        this.txtAnim()
      }, this.iSpeed);
    }

  }

}


