<div class="type-details">
  <div class="typecolumn typecolumn_content typecolumn_bg" [@content]>
      <div class="typecolumn_inner_content" #content_fixed>
          <div class="fixed" >
              <h1 [@imagef] #subtitle>{{getData[0].title}}</h1>

            </div>
          <div class="typeContent_scroll">
              <div class="typeContent_scroll_inner"  (scroll)="onWindowScroll($event)">
                  <div class="shadowContent_top"></div>
                  <h4 [ngStyle]="{'color': getData[0].color}" [@imagef]> {{getData[0].subsmallTitle}} </h4>
                  <div class="typeContent" [innerHTML]="getData[0].content">
                  </div>
                  <div class="shadowContent"></div>
              </div>

          </div>
      </div>
  </div>
  <div class="typecolumn typecolumn_img">
      <div class="typecolumn_inner_img" [@image]>
          <img src={{getData[0].img}} alt="Photo of a Shiba Inu">
      </div>
  </div>
  <div class="clearfix"></div>
</div>
